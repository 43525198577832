const returnFirstArg = (x) => x;

export default class StService {
  constructor(api, options = {}) {
    this.api = api;
    const { afterParser = returnFirstArg } = options;
    this.afterParser = afterParser;
  }

  find(params) {
    return this.api.get(params).then(this.afterParser);
  }

  update(data = {}, options = {}) {
    return this.api.post(data, options).then(this.afterParser);
  }

  modify(data = {}, options = {}) {
    return this.api.patch(data, options).then(this.afterParser);
  }

  insert(data = {}, options = {}) {
    return this.api.put(data, options).then(this.afterParser);
  }

  remove(params = {}) {
    return this.api.delete(params).then(this.afterParser);
  }
}
